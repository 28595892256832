@use "variables";
@use "media";

.detailTopInfo-nameImage-img img {
  height: 150px;
  transition-duration: 0.2s;
  transition-property: height;
  transition-timing-function: ease-in-out;
}

.detailTopInfo-nameImage-img {
  float: right;
  margin: 0 !important;
}

.drawer-desc-grey{
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 8px 10px 14px;
}

.workOrder-on-hold-button {
  max-width: 120px;
}

.border-radius-4px {
  border-radius: 4px!important;
}

/*
.dtResource-icon {
  width: 15px;

  svg {
    font-size: 16px!important;
    color: #5b5b5b;
    opacity: 0.5;
  }
}*/

.ant-drawer-header {
  // background-color: #264294;
  background-color: variables.$ebaq-orange;
  position: relative;
  padding: 16px 24px;
  color: white;
  border-bottom: 0 solid #f0f0f0!important;
  border-radius: 0 0 0 0!important;
}

.check-circle-green {
  color: variables.$ebaq-green;
}

.content-padding {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
}

.bg{
  &-gray {
    background: variables.$bg-gray;
  }
  &-light-gray {
    background: variables.$bg-light-gray;
  }

}

.fontSize11px {
  font-size: 11px;
}


.dtFocusText {
  // font-size: 1.1rem;
  // font-size: 1.01rem!important;
  color: #5d86d6 !important;
  font-weight: 600;
  // text-decoration: underline;
  // color: #496199!important;
  span {
    // font-size: 1.01rem!important;
    color: #5d86d6; // !important;
    font-weight: 600;
  }

  svg {
    color: #5d86d6;
    font-size: 1.01rem!important;
  }
}

.overLineText {
  text-decoration: line-through;
}


.sub-muted-text {
  margin-top: 2px;
  color: #b1b1b1;
  font-size: 10px !important;
  span {
    font-size: 10px; // !important;
  }
}



.loginGetHelp {
  //margin: 0 99px 0 0;
  align-self: flex-end;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 21px;
  padding-top: 14px;
  // right: 0;
  width: 100%;
  background-color: variables.$bg-gray;
  span {
    font-size: 11px;
    color: variables.$fontColor-muted;
  }

  .getHelpLink {
    margin-left: 4px;
    // color: variables.$ebaq-login-blue;
    color: variables.$ebaq-orange;
    cursor: pointer;
  }

  @include media.media1365 {
    margin-right: 80px;
  }
  @include media.media1023 {
    margin-right: 60px;
  }

  @include media.media767 {
    margin-right: 30px;
  }
  @include media.media479 {
    margin-right: 0;
  }
}


.cursor-pointer {
  cursor: pointer;
}

.text-line-through {
  text-decoration: line-through;
}

.lightText {
  font-size: 11px;
  font-weight: normal;
  color: variables.$ebaq-gray3;

  span {
    font-size: 11px;
    font-style: italic;
    color: variables.$ebaq-gray3;
  }
}

.boldText {
  font-weight: 600;
  font-size: 12px ;
  color: variables.$ebaq-dark-blue;
  margin-top: 3px;
}


.divider {
  width: 100%;
  height: 1px;
  // margin: 18px 0;
  margin-top: 18px;
  margin-bottom: 18px;
  background: variables.$drawer-background-color;
}


.rrule {
  &-forms-list {

    .formListInputs-list-item-close {
      width: 28px;
      .item-remove {
        margin-top:12px;
      }
    }

    &-container{
      padding-left: 12px;
      border-left: 2px solid variables.$border-dark;
      .formListInputs--label{
        font-size: 12px;
      }
    }
  }

  &-bordered-forms-list {
    .formListInputs-list-item {
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      border-bottom: 1px solid variables.$border-light;
      padding-top: 8px;
      padding-bottom: 8px;

      .ant-form-item{
        margin-bottom: 0;
      }

    }
  }
}

.fc{
  // .fc-h-event
  // &-h-event{
  //   background-color: var(--fc-event-bg-color);
  //   border: 1px solid var(--fc-event-border-color);
  //   display: block;
  // }

  &-event{
    background-color: variables.$ebaq-gray3;
    border: 1px solid variables.$ebaq-gray3;

    &-time{
      color: #fff!important;
      font-size:10px;
    }

    &-title{
      color: #fff!important;
      font-size:10px;
    }

  }

  &-icon {
    color: variables.$ebaq-orange;
    &:hover {

      color: white;
    }
  }


}

.fc .fc-button-primary{
  background-color: white;
  border-color: variables.$ebaq-orange;
  color: variables.$ebaq-orange;
  font-weight: 600;
  font-size: 11px;

  &:hover {
    background-color: variables.$ebaq-orange;
    border-color: variables.$ebaq-orange;
    color: white;
  }

  &:focus {
    box-shadow: none;
  }

}


.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
  background-color: variables.$ebaq-orange;
  border-color: variables.$ebaq-orange;
  color: white;
  font-weight: 600;

  &:focus {
    box-shadow: none;
  }

}


.fc .fc-toolbar-title{
  font-size: 16px;
  font-weight: 600;
}


.marginBottom12{
  margin-bottom: 12px!important;
}

.marginTop12 {
  margin-top: 12px!important;
}

.fw600{
  font-weight: 600;
}

.fw600{
  font-weight: 600;
}

.formItemMarginBottom6{
  .ant-form-item {
    margin-bottom: 6px;

  }
}

.uipError {
  padding: 8px 10px;
  margin-top: 6px;
  border-radius: 6px;
  border: 1px solid variables.$error-red-border;
  color: variables.$error-red;
  background-color: variables.$error-red-background;
  font-weight:500;
  line-height: normal;
}

.dt {
  &-h-column{
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &-wo{

    &-column{
      background-color: variables.$bg-lightBlue;
      border: 0.5px solid variables.$bg-lightBlueBorder;
      border-radius: 3px;
      padding: 4px 6px;
      line-height: 1;
    }

    &-name{
      color: variables.$ebaq-work-order-blue;
    }

    &-desc{
      color: variables.$ebaq-work-order-blue-muted;
      font-size: variables.$fontSize-small;
    }
  } // wo

  &-form{
    &-info-container {
      display: flex;
      gap: 4px;
      .tagCustom {
        padding: 2px 4px;
      }
    }
    &-rev{
      // color: variables.$error;
      border: 0.5px solid variables.$fontColor-muted;
      border-radius: 3px;
      display: flex;
      align-items: center;
      color: variables.$fontColor-muted;
      font-size: variables.$fontSize-small;
      padding-left: 3px;
      padding-right: 3px;
    }


  } // .form


} // .dt


.dividerHeaderText {
  .ant-divider-inner-text {
    background-color: variables.$ebaq-gray5;
    border: 1px solid variables.$border-light;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 3px;
  }
}

.dividerHeaderTextWhite {
  .ant-divider-inner-text {
    background-color: white;
    border: 1px solid variables.$border-light;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 3px;
  }
}


.formColorPicker{
  &-picker{
    background-color: red;
    padding: 12px;
    .chrome-picker{
      z-index: 11999;

    }
  }

}

.dtTagsColumn {


  // flex-wrap: wrap;
  // align-items: center;
  display: flex;
  justify-content:space-between;
  align-items: center;
  gap:3px;
  padding: 4px;

  &-tags {
    display: flex;
    gap: 3px;
    overflow: scroll;
    flex-grow: 1;
  }
  &-total {
    display: flex;
    align-items: center;
    justify-content:center;
    font-size:14px;
    font-weight: 600;
    background-color: #fff;
    border: 2.5px solid variables.$border-light;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    // width: 28px!important;
    // height: 28px!important;
  }
}

.condensedTagCard {

  .tagCard {
    padding: 3px 5px;
    height: 18px;
    border-radius: 7px;
    display: inline-flex;

    .tagCard-name {
      font-size: 10px;

    }
  }
}

.flexRowContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  font-style: normal;
}

.ris-r-node{
  display: flex;
  justify-content: center;
  width: 220px!important;
  margin: -5px;

  span {
    font-size: 14px;
    font-weight: bold;
    color: #264294;
  }
}

.subr-node{
  display: flex;
  // justify-content: center;
  width: 220px!important;
  margin: -5px;

  span {
    font-size: 14px;
    font-weight: bold;
    color: #264294;
  }

  .drawerResourceCard {
    width: 100%;
    // background: transparent;
    // border: none;
    margin-bottom: 0;
    padding: 4px;
    cursor: pointer;
    &--leftIcon {
        max-width: 52px;
    }
  }
}


.show-more-collapse {

  .ant-collapse {


    &-header {
      justify-content: flex-end;
      // padding-right: 30px!important;
      padding-right: 0!important;

      &-text{
        flex: none!important;
        font-weight: 600;
        font-size:11px;
        color: variables.$ebaq-orange;
        padding-right: 16px;
      }

    } // header

    &-arrow{
      color: variables.$ebaq-orange;
      font-size:10px!important;
      right:0!important;
    }

    &-content{

      &-box{
        // background-color: #fff3f3;
        // border: 1px solid #fce7e7;
        padding: 0 8px;
        // border-radius: 8px;
        // .formElementLabel__labelName{
        //   color: #9a2828;
        // }
        //
        // .name{
        //   color: #9a2828;
        // }
      }

    }

  } // ant-collapse



}




.padding16{
  padding: 16px;
}

.padding12{
  padding: 12px;
}

.mulDrawer {
  &-multi {
    &-delete {
      .ant-drawer-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: variables.$delete-background-color;
      }

    }

  }
}

.lineHeight115 {
  line-height: 1.15;
}



